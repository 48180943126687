import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Decoration2 from '../components/Decoration/Decoration-2';
import VideoYoutube from '../components/VideoYoutube';

import '../assets/styles/pages/opportunity-page.scss';
import StartJourneyButton from '../components/StartJourneyButton';

const OpportunityPage = ({ data }) => {
  const prismicData = data.prismic.allOpportunity_pages.edges[0].node;
  return (
    <Layout>
      <SEO title="Opportunity" />
      <div className="opportunity-page">
        <FirstSection data={data} prismicData={prismicData} />
        <SecondSection data={data} prismicData={prismicData} />
        <ThirdSection prismicData={prismicData} />
        <FourthSection data={data} prismicData={prismicData} />
        <FifthSection prismicData={prismicData} />
      </div>
    </Layout>
  );
};

const FirstSection = ({ prismicData: { first_section_title, first_section_imageSharp } }) => (
  <section className="first-section">
    <section className="common-first-section">
      <div className="container">
        <div className="cfs__wrapper">
          <div className="cfs__l">
            <div>
              <h1 className="c-h1 cfs__l__title">{RichText.render(first_section_title)}</h1>
              <StartJourneyButton text="Start your journey" />
            </div>
          </div>
          <div className="cfs__r">
            <Decoration2 />
            {first_section_imageSharp && (
              <Img fluid={first_section_imageSharp.childImageSharp?.fluid} />
            )}
          </div>
        </div>
      </div>
    </section>
  </section>
);
const SecondSection = ({
  prismicData: {
    // eslint-disable-next-line camelcase
    second_section_subtitle,
    second_section_title,
    second_section_content,
    second_section_imageSharp,
  },
}) => (
  <section className="second-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5">
          <div className="second-section__left">
            {second_section_imageSharp && (
              <Img fluid={second_section_imageSharp.childImageSharp.fluid} />
            )}
          </div>
        </div>
        <div className="col-12 col-md-6 offset-lg-1 second-section__right">
          <div className="c-h5--light">{RichText.asText(second_section_subtitle)}</div>
          <div className="c-h1">{RichText.asText(second_section_title)}</div>
          {RichText.render(second_section_content)}
        </div>
      </div>
    </div>
  </section>
);

const ThirdSection = ({
  // eslint-disable-next-line camelcase
  prismicData: { third_section_title, third_section_content, video_link, video_preview },
}) => (
  <section className="third-section">
    <div className="container">
      <div className="row">
        <div className="col-md-10 col-lg-8 mx-auto">
          <div className="third-section__wrapper h-100">
            <div className="c-h1">{RichText.asText(third_section_title)}</div>
            {RichText.render(third_section_content)}
            <div className="h-100">
              {video_link && <VideoYoutube url={video_link.url} imagePreview={video_preview.url} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const FourthSection = ({
  // eslint-disable-next-line camelcase
  prismicData: { fourth_section_title, fourth_section_content, fourth__section_imageSharp },
}) => (
  <section className="fourth-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5">
          <div className="fourth-section__left">
            <div className="c-h1">{RichText.asText(fourth_section_title)}</div>
            {RichText.render(fourth_section_content)}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
          <div className="fourth-section__right w-100">
            {fourth__section_imageSharp && (
              <Img fluid={fourth__section_imageSharp.childImageSharp.fluid} />
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
);

// eslint-disable-next-line camelcase
const FifthSection = ({ prismicData: { fifth_section_content } }) => (
  <section className="c-section-mt fifth-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-9 mx-auto">
          <div className="fifth-section__wrapper">
            <div className="c-h1 text-white ">{RichText.asText(fifth_section_content)}</div>
            <StartJourneyButton text="Sign up now" styleName="c-btn--3" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  query {
    prismic {
      allOpportunity_pages {
        edges {
          node {
            first_section_title
            first_section_image
            first_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 816, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            second_section_subtitle
            second_section_title
            second_section_image
            second_section_content
            second_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 665, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            video_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            video_preview
            third_section_title
            third_section_content
            fourth_section_title
            fourth_section_content
            fourth__section_image
            fourth__section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 816, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            fifth_section_content
          }
        }
      }
    }
  }
`;

export default OpportunityPage;
